



































































































































import "reflect-metadata";
import { Vue, Component, Prop, Ref, Watch } from "vue-property-decorator";
import AvaAsset from "@/js/AvaAsset";
import MnemonicWallet from "@/js/wallets/MnemonicWallet";
import NftCol from "./NFTCol.vue";
import Spinner from "@/components/misc/Spinner.vue";
import Tooltip from "@/components/misc/Tooltip.vue";

import Big from "big.js";
import { BN } from "avalanche/dist";
import { ONEDJTX } from "avalanche/dist/utils";
import { bnToBig } from "@/helpers/helper";
import { priceDict } from "@/store/types";
import { WalletType } from "@/js/wallets/types";
import UtxosBreakdownModal from "@/components/modals/UtxosBreakdown/UtxosBreakdownModal.vue";

@Component({
  components: {
    UtxosBreakdownModal,
    Spinner,
    NftCol,
    Tooltip,
  },
  filters: {
    cleanDjtxBN(val: BN) {
      let big = Big(val.toString()).div(Big(ONEDJTX.toString()));
      return big.toLocaleString();
    },
  },
})
export default class BalanceCard extends Vue {
  isBreakdown = true;

  $refs!: {
    utxos_modal: UtxosBreakdownModal;
  };

  updateBalance(): void {
    this.$store.dispatch("Assets/updateUTXOs");
    this.$store.dispatch("History/updateTransactionHistory");
  }

  showUTXOsModal() {
    this.$refs.utxos_modal.open();
  }
  get ava_asset(): AvaAsset | null {
    let ava = this.$store.getters["Assets/AssetAVA"];
    return ava;
  }

  toggleBreakdown() {
    this.isBreakdown = !this.isBreakdown;
  }

  get avmUnlocked(): BN {
    if (!this.ava_asset) return new BN(0);
    return this.ava_asset.amount;
  }

  get avmLocked(): BN {
    if (!this.ava_asset) return new BN(0);
    return this.ava_asset.amountLocked;
  }

  get evmUnlocked(): BN {
    if (!this.wallet) return new BN(0);
    // convert from ^18 to ^9
    let bal = this.wallet.ethBalance;
    return bal.div(new BN(Math.pow(10, 9).toString()));
  }

  get totalBalance(): BN {
    if (!this.ava_asset) return new BN(0);

    let tot = this.ava_asset.getTotalAmount();
    // add EVM balance
    tot = tot.add(this.evmUnlocked);
    return tot;
  }

  get totalBalanceBig(): Big {
    if (this.ava_asset) {
      let denom = this.ava_asset.denomination;
      let bigTot = bnToBig(this.totalBalance, denom);
      return bigTot;
    }
    return Big(0);
  }

  get djtxPriceText() {
    return this.priceDict.usd;
  }

  get totalBalanceUSD(): Big {
    let usdPrice = this.priceDict.usd;
    let usdBig = this.totalBalanceBig.times(Big(usdPrice));
    return usdBig;
  }

  get totalBalanceUSDText(): string {
    if (this.isUpdateBalance) return "--";
    return this.totalBalanceUSD.toLocaleString(2);
  }
  // should be unlocked (X+P), locked (X+P) and staked and lockedStakeable
  get balanceText(): string {
    if (this.ava_asset !== null) {
      let denom = this.ava_asset.denomination;
      return this.totalBalanceBig.toLocaleString(2);
    } else {
      return "?";
    }
  }

  get balanceTextLeft(): string {
    if (this.isUpdateBalance) return "--";
    let text = this.balanceText;
    if (text.includes(".")) {
      let left = text.split(".")[0];
      return left;
    }
    return text;
  }

  get balanceTextRight(): string {
    if (this.isUpdateBalance) return "";
    let text = this.balanceText;
    if (text.includes(".")) {
      let right = text.split(".")[1];
      return right;
    }
    return "";
  }

  // Locked balance is the sum of locked DJTX tokens on X and P chain
  get balanceTextLocked(): string {
    if (this.isUpdateBalance) return "--";

    if (this.ava_asset !== null) {
      let denom = this.ava_asset.denomination;
      let tot = this.platformLocked.add(this.platformLockedStakeable);
      // let otherLockedAmt = this.platformLocked.add(this.platformLockedStakeable)
      let pLocked = Big(tot.toString()).div(Math.pow(10, denom));
      let amt = this.ava_asset.getAmount(true);
      amt = amt.add(pLocked);

      return amt.toLocaleString(denom);
    } else {
      return "--";
    }
  }

  get balanceTextMultisig() {
    if (this.isUpdateBalance) return "--";

    if (this.ava_asset !== null) {
      let denom = this.ava_asset.denomination;
      return bnToBig(
        this.avmMultisig.add(this.platformMultisig),
        denom
      ).toLocaleString();
    } else {
      return "--";
    }
  }

  get avmMultisig(): BN {
    if (this.ava_asset !== null) {
      return this.ava_asset.amountMultisig;
    } else {
      return new BN(0);
    }
  }

  get platformBalance() {
    return this.$store.getters["Assets/walletPlatformBalance"];
  }

  get platformUnlocked(): BN {
    return this.platformBalance.available;
  }

  get platformMultisig(): BN {
    return this.platformBalance.multisig;
  }

  get platformLocked(): BN {
    return this.platformBalance.locked;
  }

  get platformLockedStakeable(): BN {
    return this.platformBalance.lockedStakeable;
  }

  get unlockedText() {
    if (this.isUpdateBalance) return "--";

    if (this.ava_asset) {
      let xUnlocked = this.ava_asset.amount;
      let pUnlocked = this.platformUnlocked;

      let denom = this.ava_asset.denomination;

      let tot = xUnlocked.add(pUnlocked).add(this.evmUnlocked);

      let amtBig = bnToBig(tot, denom);

      return amtBig.toLocaleString(denom);
    } else {
      return "--";
    }
  }

  get pBalanceText() {
    if (!this.ava_asset) return "--";
    if (this.isUpdateBalance) return "--";

    let denom = this.ava_asset.denomination;
    let bal = this.platformUnlocked;
    let bigBal = Big(bal.toString());
    bigBal = bigBal.div(Math.pow(10, denom));

    if (bigBal.lt(Big("1"))) {
      return bigBal.toLocaleString(9);
    } else {
      return bigBal.toLocaleString(3);
    }
  }

  get stakingAmount(): BN {
    return this.$store.getters["Assets/walletStakingBalance"];
  }

  get stakingText() {
    let balance = this.stakingAmount;
    if (!balance) return "0";
    if (this.isUpdateBalance) return "--";

    let denom = 9;
    let bigBal = Big(balance.toString());
    bigBal = bigBal.div(Math.pow(10, denom));

    if (bigBal.lt(Big("1"))) {
      return bigBal.toString();
    } else {
      return bigBal.toLocaleString();
    }
  }

  get wallet(): WalletType | null {
    return this.$store.state.activeWallet;
  }

  get isUpdateBalance(): boolean {
    if (!this.wallet) return true;
    return this.wallet.isFetchUtxos;
  }

  get priceDict(): priceDict {
    return this.$store.state.prices;
  }

  get hasLocked(): boolean {
    return (
      !this.avmLocked.isZero() ||
      !this.platformLocked.isZero() ||
      !this.platformLockedStakeable.isZero()
    );
  }
  get hasMultisig(): boolean {
    return !this.avmMultisig.isZero() || !this.platformMultisig.isZero();
  }
}
